var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","dense":"","floating":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("widgets.planeteOnlineNews.name")))])],1),(!_vm.moving)?_c('v-card-text',{staticClass:"pt-2"},[_vm._l((_vm.getRealNews),function(newsletter,i){return _c('v-hover',{key:i + 'a',scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mb-4",attrs:{"dense":"","hover":""},on:{"click":function($event){return _vm.goToCom(newsletter._id)}}},[_c('v-card-subtitle',{staticClass:"mt-0 mb-0 pb-0"},[_vm._v(" "+_vm._s(_vm.lang == "fr" ? newsletter.label : newsletter.label_en)+" ")]),_c('v-card-subtitle',{staticClass:"pt-1 pb-0"},[_vm._l((_vm.getUniqueModule(newsletter.module)),function(module,i){return [(module)?_c('planete-icon',{key:i,staticClass:"mr-2",attrs:{"height":"24","width":"24","dark":_vm.$vuetify.theme.dark}},[_vm._v(_vm._s(_vm.getIcon(module)))]):_vm._e()]}),_c('br'),_c('v-chip',{class:[
              'rounded',
              'py-1',
              'px-2',
              { 'white--text': !_vm.seen(newsletter) } ],attrs:{"x-small":"","color":!_vm.seen(newsletter) ? 'blue' : ''}},[_vm._v(_vm._s(!_vm.seen(newsletter) ? _vm.$t("actions.notSeen") : _vm.$t("actions.seen")))])],2),_c('v-card-text',{staticClass:"text-center pt-0"},[_c('v-btn',{staticClass:"mt-1 float-right",attrs:{"text":"","x-small":""}},[_vm._v(" "+_vm._s(_vm.$t("widgets.planeteOnlineNews.seeMore"))+" "),_c('v-icon',{attrs:{"x-small":"","right":""}},[_vm._v("mdi-page-next")])],1),_c('br')],1)],1)]}}],null,true)})}),_vm._l((_vm.news),function(newsletter,i){return _c('v-hover',{key:i + 'b',scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{staticClass:"pt-4 mb-4",attrs:{"dense":"","hover":""},on:{"click":function($event){return _vm.$router.push({
            name: 'PlaneteOnlineNews',
            params: { id: newsletter.id },
          })}}},[_c('div',{staticClass:"text-center"},[_c('planete-icon',{attrs:{"height":"32","width":"32"}},[_vm._v("planete-online")])],1),_c('v-card-title',{staticClass:"news-title text-center pt-2"},[_c('span',[_vm._v(_vm._s(newsletter.title))])]),_c('v-card-text',{staticClass:"text-justify pb-1 font-12"},[_vm._v(" "+_vm._s(_vm.tease(newsletter.text))),_c('br')]),_c('v-card-text',{staticClass:"text-center pt-0"},[_c('v-btn',{attrs:{"x-small":"","text":""}},[_vm._v(" "+_vm._s(_vm.$t("widgets.planeteOnlineNews.seeMore"))+" "),_c('v-icon',{attrs:{"x-small":"","right":""}},[_vm._v("mdi-page-next")])],1)],1)],1)]}}],null,true)})})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }